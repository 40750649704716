import React from 'react'

import Contact from '../Contact'
function Index() {
  return (
    <div className='m-1 flex flex-col justify-center'>
        <br></br><br></br>
    <Contact></Contact>
    </div>
  )
}

export default Index