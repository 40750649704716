import React from 'react'
import { useState, useContext } from "react";
import Index from './pages/Index';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Context } from '../App';

function NavButton(props: any) {
  return (
    <li className={"mr-3"}>
      <Link to={props.to} className="bg-green-950 rounded hover:border-gray-200 text-white hover:bg-green-700 py-1 px-3" >{props.text}</Link>
    </li>
  )
}

function MyNavBar() {
  const [activeColor, setActiveColor] = useState("bg-slate-800");
  const [buttonDist] = useState("3")
  //@ts-ignore
  const {madMode, setMadMode}= useContext(Context)
  return (

    <div className='text-red-300 text-center flex flex-col'>

        <div>
          <ul className="flex justify-center">

            <NavButton to="/" text="Home"></NavButton>

            <NavButton to="/Full-Story" text="Full Story"></NavButton>
            <NavButton to="/AboutMe" text="About Me"></NavButton>
            {/* <li className="mr-3">
                <Link to="" className="bg-green-950 rounded hover:border-gray-200 text-white hover:bg-gray-200 py-1 px-3" >Pill</Link>
              </li> */}

          </ul>
        </div>
        
        <div className='flex justify-center'>
        <button className={`${madMode === true? 'bg-green-800 text-green-400 hover:bg-green-900' : 'bg-red-800 hover:bg-red-900'} p-3 px-4 rounded-xl mt-5`} onClick={() => {
          setMadMode(!madMode)
        }}>
          Turn Schitzo Simulation {madMode === true? "off" : "on"}
        </button>
        </div>

        {madMode === false? '': 
        
        <div className='mt-5'>
        <p className='text-red-300 text-md'>My entire <s>life</s> website is a silly art project. Don't take it seriously.</p>
            <br></br>
          <p className='text-red-300 text-md'> Most of my work is done during somewhat manic episodes, so don't be influenced by what I say. I regret everything I do, but I'm leaving it all here for documentation.</p>

        </div>
        }

    </div>

  )
}

export default MyNavBar