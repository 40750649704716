import React from 'react'
import { Context } from '../../App';
import { useContext } from 'react';

function Story() {
    //@ts-ignore
    const { madMode, setMadMode } = useContext(Context)

    return (
        <div className='text-slate-300 text-center bg-slate-800 p-8'>
            <div className='text-slate-300 text-3xl '><b>All the characters I lewd are 18+ and consenting.</b></div>

            <div className='underline text-2xl'><br></br>This page tries to provide more context of the story and lore that I cannot afford to make art of. And I'm not just saying that because I'm trying to comply with the regulations of the ecosystems I exist in. This is really really really (not sarcasm) really the lore :').</div>
            <br></br><br></br>
            <b className='text-center text-2xl underline'>Definitions</b><br></br>
            <br></br>

            <b className='text-xl text-center underline'>Filly </b><br></br><b className='text-pink-200'>Filly</b> (Also called fillly, fille, fili, filler, filla, fillee, fili, fille, ect.) is a phrase I made up one day to label an ancient race of 69,000 year olds I created, who are nicknamed in the world for their unique anatomy. They're called "fillies" because they are small but very fillable (with solids or fluids) relative to their initial volume.
            <br></br><br></br>
            When a filly is consenting, their cervix might loosen up and allow for cervical penetration (which "locks" a stallion's flare in until they cum), or the back of their vaginal walls become stretchier, allowing for the entire cervical wall to be pushed forward, so they can comfotably fit a full horsecock.
            It was evolutionarily beneficial to give fillies this design feature because they are a small race with a desire to breed. So jokingly, everybody calls them "fillable".
            <br></br> <br></br>
            If you ever believe a character is not consenting, it's because of lore you're mistakedly not seeing yet because I can't find time to animate it (but you can do it for me if you want, if you can match my current level of artistic quality), but trust me, the lore goes as deep as the cervix of a filly in heat.

            <br></br><br></br>
            <b className='text-center text-2xl underline'>The Full story</b><br></br>
            <br></br>
            - The page where a pink pony is in a dungeon cell "traps" a cock in her "dungeon": There is some fillycism in her local province against mating with the filly race, and the adult filly, who knows that that kind of teasing makes him rock hard, takes advantage to escape.
            {madMode === false ? '' :
                <div>
                             <br></br><br></br>
            This is not commentary about how there are regulations that stop me from making art of small flatchested girls to the full extent that I could (compared to large big-booba girls), and if you accuse me of such, I might sue you for libel for ruining this totally thriving art business. In fact, I might sue you for negative revenue, since that seems to be how much I am currently making. I am not sueing you for negative revenue because outrage is a popular method of generating views and support for one's content in the modern day, and if you accuse me of such, I might make art of hot-angry-outrage-sex, not of our two characters, because that would be illegal somewhere. I am not laying down incentives for ponies to generate outrage towards me because I want to profit off of it like most of the noble-ponies I see. I believe that the noble-ponies tend to be noble ponies.
            <br></br><br></br>
            I unironically think youtubers who make videos of degenerates to bring light to them while calling them disgusting so that they don't get boofed by the rules and regulations in place are somewhat based. Seriously, that guy who makes videos where he pretends to not enjoy skyrim sex mods while going through skyrim sex mods is making a killing, and doing the lords work by making more degenerates for this messed up world. Now imagine that scenario the skyrim sex mods guy is in, but for me, it's everything, - not just a socially-acceptable-since-the-late-2010s degeneracy thing like sex modding, hentai or yiff, and that's how I have to live my life. Most degenerates covered by Turkey Tom, most insane cunts seen on the next reaction channel where somebody is having a public freakout, I just see as deterministic evolution, or as most ponies call it, "fate". I believe that genuine human progress is driven by humans who tend to have deviant mindsets, and that as a bi-product of those mindsets, they tend to become what we consider deviants and degenerates. I believe that the overton window for what defines what a degenerate is will always perpetually shift when one degenerate with a very weird fetish as a bi-product of a highly deviant brain will become selected by the environment to outbreed the average, and that those set of genes that casued the deviancy, once they propogate to the population, will cause the population to have a spectrum of that fetish.
            <br></br><br></br>
            <div className='font-bold text-xl'>
                After all, somebody had to be the first to get dopamine from eating flesh, and they were history's very first and vore and guro-fetishist.
                <br></br>
                I worship the old gods at the alter of <s>blood</s> the barbecue grill.
            </div>
            <br></br><br></br>
            Then, when their children survived and outbred with their violent tendencies, we all got our canines and our thirst for protein. And snuff became normalized to the extent that it was functional. But every once in a while, the overton window driven by genetics will push against the pane a bit more, and if the environment is just right, everybody becomes a bit more of a sadist.
            <br></br><br></br>
            <div className='text-yellow-100'>
                At the current rate, I expect everybody to have a skyrim-sex-modding fetish in less than 10 generations (1000 years) due to the selective advantages of being able to use and modify technology. The skyrim-sex-mod fetish is just a biproduct of another set of genes, you see?
            </div>

            <br></br><br></br>
            But once everybody has a skyrim sex mod fetish, and the overton window shifts, the cycle repeats. Back or forth, I do not know which direction it will go. But it will repeat. Forever. It is the definition of insanity. And ponies who gaze through the window, seen its incoprehensible shape, are no better off.
            <br></br><br></br>
            Sometimes, ignorance is an evolutionary advantage. Pretend this window I talk about exists and is comprehensible, pretend that I've seen it, and that it has made me insane. Pretend that I can only comprehend it because of a set of genes that give me a certain kind of personality. Pretend that maybe you see it, but have not gone insane, because you have a very particular personality driven by your own set of genes to a great extent. Could we construct infohazardous weapons of large scale eugenics if we distilled these concepts?
            <br></br><br></br>
            You know that scene in that movie where gazing at the thing makes you insane, and the ponies who go insane force other ponies to gaze into it? I think there's a truth about that. Maybe it's an engrained survival mechanism to want the ponies around you to be as insane as you are. To strike before you are striked down and isolated for your deviance. Maybe that's why some of the more fetishy ponies look down on vanilla ponies. Out of a premature rejection of ponies who they feel will never truly accept them if they truly knew who they were. There is much comfort in knowing that ponies around you would accept you if they truly knew you to the full extent that you feel like you knew yourself. Sadly, I think we tend to not know ourselves as well as we think we do.

            <br></br> <br></br>
            <div className='text-red-400 font-bold'>THESE REGULATIONS ARE LITERALLY EUGENICS AGAINST GIRLS WITH TOMBOY GENES AND SMALL GUYS LIKE ME WHO GIVE THEM SUCH GENES REEEEEEEEEEEEEEEEEEEE. HOW THE FUCK IS MY FILLY DAUGHTER EXPECTED TO SURVIVE IN THE CUM FARMING MARKET?</div>
            <br></br>
            <br></br>
            But yeah, my religious belief (but not political, as having this be a political belief is too impractical) is called "degeneracy accelerationism". The only way to accelerate humanity's ability to develop technology is to allow maximum degeneracy to exist. Morals don't matter, because what the right morals are will inevitably change when the winning set of genes propogate. By controlling our environment, we are only controlling for what the inevitabilities will be for short-term gains.<br></br> <br></br>

            Life is a cum farmer's-market, and only the rich can afford organic and GMO-free. If only you knew how bad things really were for independent cum farmers. We always talk about human rights and the rich, but let me give you the one true human right. The right to produce all-natural, 100% organic cum, by the definition of organic defined by your heart, not the FDAOE. Anything that hinders this in any way, I believe, should be vehemently fought against. We need to seize the means of reproduction.

                </div>
            }
   
        </div>


    )
}

export default Story